import React, { Fragment, useState, useEffect } from "react";

import { useStore } from 'react-create-use-store';
import store from './../../adderStore.js'

import { withTranslation } from 'react-i18next'

import 'react-toastify/dist/ReactToastify.css';


import { ThemeProvider } from '@mui/material/styles';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import ThemeGeroa from './../../ThemeGeroa.js'
//import auth from './../../apis/auth-helper.js'
import { listaSolicitudes } from './../../apis/api-solicitud.js'


import MensajeCabecera from "../../components/MensajeCabecera.js";

import { RedondeaPrecio } from "../../config/funcionesPropias.js";



const Clientes = ({ t, ...props }) => {


    const { state, actions } = useStore(store);


    const [estado, setEstado] = useState(
        {
            mercado: undefined, // 1
            lineasClientes: []

        }
    )




    // Carga Inicial cuando se forma state. //
    useEffect(() => {
        let clientes
        if (estado.lineasClientes.length === 0) {
            loadSolicitudesIniciales()
        }
    })



    // Cargar las Facturas. //
    async function loadSolicitudesIniciales() {
        let params = {
            proveedor: state.codProveedor
        }
        await listaSolicitudes(params).then((data) => {
            console.log('datos = ', data)
            if (data.error) {
                console.log(data.error)
            }
            else {
                preparaVisualizacion(data)
            }
        })
    }



    // Carga de datos Inicial --> despues de la carga renderiza
    function preparaVisualizacion(data) {
        if (data.length > 0) {
            // Añade AceptarSolicitud
            let tpData = data.map((l, i) => {
                l.Aceptar = false
                l.Denegar = false
            })
            estado.lineasClientes = tpData
            // Fuerza Renderizado
            setEstado({ ...estado, lineasClientes: data })
            actions.cambiarMensaje("")
        } else {
            actions.cambiarMensaje(t("Clientes.NoHaySolicitudesDeClientes"))
        }

    }



    const tituloYFiltros = (
        <Fragment>
            <Box sx={{ display: 'flex' }} height="50px">
                <Box sx={{ width: 3 / 3 }}>
                    <Typography variant="verde30">
                        {t("Clientes.SolicitudesDeAlta")}
                    </Typography>
                </Box>
            </Box>
        </Fragment>
    )


    const handleChange = (campo, linea) => event => {
        //event.stopPropagation()
        if (campo == "Aceptar") {
            linea.Aceptar = !linea.Aceptar
            linea.Denegar = linea.Aceptar === true ? linea.Denegar = false : linea.Denegar = linea.Denegar
        } else {
            linea.Denegar = !linea.Denegar
            linea.Aceptar = linea.Denegar === true ? linea.Aceptar = false : linea.Aceptar = linea.Aceptar
        }

        setEstado({ ...estado, lineasClientes: estado.lineasClientes })
    }





    const handleSubmit = () => {
/*
        console.log('lineas = ', estado.lineasClientes)


        let clientes = ""

        estado.lineasClientes.forEach(l => {
            if (l.Aceptar || l.l.Denegar) {
                clientes.push(l)
            }
        })
        consoel.log('clientes = ', clientes)
        await enviarMailsTegistrarSolicitudes(clientes).then((data) => {
            if (data.error) {
                // Se podría dar un mensaje por si falla. //
                console.log("Error:", data)
            }
            else {
                // Ha creado el pedido. //
                //props.actualizador(true)
                console.log('Fin Correcto')
            }
        })
    }
        */
}


const listaClientes = (
    estado.lineasClientes.length > 0 && (
        <Grid sx={{ mt: 0 }}>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell><Typography variant="azul15">{t("Clientes.ClienteSolicitante")}</Typography></TableCell>
                            <TableCell><Typography variant="azul15">{t("Clientes.MercadoSolicitado")}</Typography></TableCell>
                            <TableCell><Typography variant="azul15">{t("Clientes.FechaSolicitud")}</Typography></TableCell>
                            <TableCell align="right"><Typography variant="azul15">{t("Clientes.AceptarSolicitud")}</Typography></TableCell>
                            <TableCell align="right"><Typography variant="azul15">{t("Clientes.DenegarSolicitud")}</Typography></TableCell>

                        </TableRow>

                    </TableHead>
                    <TableBody>
                        {estado.lineasClientes.map((l, i) => (
                            <TableRow
                                key={"Prod" + l.ProCod + i}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>{l.CliCod + " - " + l.CliNom + " - " + l.CliTel}</TableCell>
                                <TableCell>{l.SolMer + ' - ' + (state.idioma === "Es" ? l.MerDesC : l.MerDesE)}</TableCell>
                                <TableCell>{l.SolFec}</TableCell>
                                <TableCell align="right">
                                    <FormControlLabel
                                        aria-label="Acknowledge"
                                        onClick={event => event.stopPropagation()}
                                        onFocus={event => event.stopPropagation()}
                                        control={<Checkbox
                                            id={"CliSol" + i + l.ProCod}
                                            checked={l.Aceptar}
                                            onChange={handleChange("Aceptar", l)}
                                        />}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <FormControlLabel
                                        aria-label="Acknowledge"
                                        onClick={event => event.stopPropagation()}
                                        onFocus={event => event.stopPropagation()}
                                        control={<Checkbox
                                            id={"CliSol" + i + l.ProCod}
                                            checked={l.Denegar}
                                            onChange={handleChange("Denegar", l)}
                                        />}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <br />
            <Grid container display="flex" justifyContent="flex-end">
                <Button
                    variant="contained"
                    onClick={handleSubmit}
                >   {t("Clientes.Aceptar")}
                </Button>
            </Grid>
        </Grid>
    )
)


return (
    <ThemeProvider theme={ThemeGeroa}>
        <Container sx={{ mt: 1.5 }}>
            {tituloYFiltros}
            <MensajeCabecera />
            {listaClientes}
            <br />
        </Container>
    </ThemeProvider>
);
}

export default withTranslation()(Clientes)