import React, { Fragment, useState, useEffect } from "react";
import { useStore } from 'react-create-use-store';
import { withTranslation } from 'react-i18next'

import 'react-toastify/dist/ReactToastify.css';

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Container from '@mui/material/Container';

import Typography from '@mui/material/Typography';

import { ThemeProvider } from '@mui/material/styles';
import Grid from "@mui/material/Grid";


import store from './../../adderStore.js'
import ThemeGeroa from './../../ThemeGeroa.js'

import { APIProvider, Map, AdvancedMarker, Pin, InfoWindow } from '@vis.gl/react-google-maps';


import { BuscaIndice, EsUsuarioEspecial } from './../../config/funcionesPropias.js'
import BotonXlsx from "../../components/BotonXlsx.js";
import Button from "@mui/material/Button";
import DialogCrearModificarPunto from './DialogModificarPunto.js'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { listaPuntosProvCoor, updatePuntoProvCoor, borrarPunto } from './../../apis/api-punto.js'
import EditIcon from '@mui/icons-material/Edit';
import config from './../../config/config.js'


const MisMapas2 = ({ t, ...props }) => {

    //const jwt = auth.isAuthenticated()
    //console.log('autenticado = ', jwt)

    const { state, actions } = useStore(store);

    const [estado, setEstado] = useState(
        {
            carga: true,
            todosPuntos: [],
            puntosAVer: [],
            lineasExcel: [],
            dialogCrearModificar: '',
            soloPuntosMios: false,
            soloPuntosCad: false,
            posCentral: { lat: 43.0745200, lng: -2.4841500 },        // Mondragon
        }
    )
    // Posicion de ENEEK y MONDRAGON. //
    //let posEneek = { lat: 43.21290376030901, lng: -2.726953625679016 }
    //const posMondragon = { lat: 43.0745200, lng: -2.4841500 }


    // Si cambia de Idioma. Recargar los tipos de Puntos. //
    useEffect(() => {
        const tiposPuntos = [
            t("MisMapas.Tipo0"),
            t("MisMapas.Tipo1"),
            t("MisMapas.Tipo2"),
            t("MisMapas.Tipo3"),
            t("MisMapas.Tipo4"),
            t("MisMapas.Tipo5"),
            t("MisMapas.Tipo6"),
            t("MisMapas.Tipo7"),
            t("MisMapas.Tipo8"),
            t("MisMapas.Tipo9"),
            t("MisMapas.Tipo10"),
            t("MisMapas.Tipo11")
        ]
        actions.cambiarTiposDePuntos(tiposPuntos)

        // Ordenar el Array de FamiliasSubfamilas por descripción. //
        let famSub = state.familiasSubfamilias
        famSub.sort((a, b) => {
            let x, y
            if (state.idioma === "Es") {
                // Descripciones Cashtellano
                x = (a.FamDesC + a.SubDesC).toUpperCase()
                y = (b.FamDesC + b.SubDesC).toUpperCase()
            } else {
                // Descripciiones Euskera
                x = (a.FamDesE + a.SubDesE).toUpperCase()
                y = (b.FamDesE + b.SubDesE).toUpperCase()
            }
            if (x < y) return -1
            if (x > y) return 1
            return 0
        })
        // Guardar el array ordenado. //
        actions.cambiarFamiliasSubfamilias(famSub)

    }, [state.idioma, state.familiasSubfamilias, actions])


    useEffect(() => {
        if (estado.carga) {
            loadLineasPuntosIniciales()
            // Actualiza el estado sin Repintar. //
            estado.carga = false
        }
    })



    // Contar los productos para establecer el calendario
    async function loadLineasPuntosIniciales() {
        await listaPuntosProvCoor({ proveedor: state.codProveedor, esProductor: state.esProductor ? "1" : "0" }).then((data) => {
            if (data.error) {
                console.log(data.error)
            }
            else {
                preparaVisualizacionYExcel(data)
            }
        })
    }



    function preparaVisualizacionYExcel(data) {

        if (data.length > 0) {
            // Reorganiza las lineas
            let tmpCodPunto = ""
            let resultado = []
            let resultadoExcel = []
            let fecha = ""
            //
            for (let i = 0; i < data.length; i++) {
                if (data[i].PunNum !== tmpCodPunto) {
                    tmpCodPunto = data[i].PunNum
                    resultado.push({
                        numero: data[i].PunNum,
                        titulo: data[i].PunNom,
                        productor: data[i].PunPro,
                        tipo: data[i].PunTip,
                        nota: data[i].PunNot,
                        posicion: { lat: data[i].PunLat, lng: data[i].PunLon },
                        poblacion: data[i].PunPob,
                        visible: data[i].PunVer,
                        familiasMias: [],
                        familiasAjenas: [],
                        open: false
                    })
                    /* Cambio del excel
                    resultadoExcel.push({
                        Nombre: data[i].PunNom,
                        Latitud: data[i].PunLat,
                        Longitud: data[i].PunLon,
                        Tipo: state.tiposDePuntos[data[i].PunTip],
                        nota: data[i].PunNot,
                        Productor: data[i].PunPro,
                        Familias: ""
                    })
                    */
                }

                let familia = data[i].PrPFam
                let subFami = data[i].PrPSub
                let indexFam = state.familiasSubfamilias.findIndex(sub => sub.FamCod + sub.SubCod === familia + subFami);
                let nombreFam = ((index) => {
                    let nombre = ""
                    if (index !== -1) {
                        let f, s
                        if (state.idioma === "Es") {
                            f = state.familiasSubfamilias[index]?.FamDesC
                            s = state.familiasSubfamilias[index]?.SubDesC
                        } else {
                            f = state.familiasSubfamilias[index]?.FamDesC
                            s = state.familiasSubfamilias[index]?.SubDesC
                        }
                        nombre = f + (s === "" ? "" : " - " + s)
                    }
                    return nombre
                })(indexFam)



                fecha = data[i].PrPFec !== null ? data[i].PrPFec.substr(8, 2) + "-" + data[i].PrPFec.substr(5, 2) + "-" + data[i].PrPFec.substr(0, 4) : ""
                if (data[i].PrPPro === state.codProveedor) {
                    resultado[resultado.length - 1].familiasMias.push({
                        codFamilia: familia, // data[i].PrPFam,
                        codSubFamilia: subFami, // data[i].PrPSub,
                        familia: nombreFam, // state.idioma === "Es" ? data[i].FamDesC : data[i].FamDesE,
                        fecha: fecha, // dd-mm-aaaa
                        productorFamilia: data[i].PrPPro
                    })
                } else {
                    resultado[resultado.length - 1].familiasAjenas.push({
                        codFamilia: familia, // data[i].PrPFam,
                        codSubFamilia: subFami, // data[i].PrPSub,
                        familia: nombreFam, // state.idioma === "Es" ? data[i].FamDesC : data[i].FamDesE,
                        fecha: fecha,
                        productorFamilia: data[i].PrPPro
                    })
                }
                /* Cambio del excel
                resultadoExcel[resultadoExcel.length - 1].Familias += (resultadoExcel[resultadoExcel.length - 1].Familias !== '' ? ' // ' : '') + state.idioma === "Es" ? data[i].FamDesC : data[i].FamDesE
                */
            }
            // Barre el resultado y desglosalo
            let familias
            resultado.forEach((r, i) => {
                //
                familias = [...r.familiasMias, ...r.familiasAjenas].filter(f => { return f.codFamilia !== null })
                //
                if (familias.length > 0) {
                    familias.forEach((f, j) => {
                        resultadoExcel.push({
                            Nombre: r.titulo,
                            Latitud: r.posicion.lat,
                            Longitud: r.posicion.lng,
                            Tipo: r.tipo !== -1 ? state.tiposDePuntos[r.tipo] : '',
                            Nota: r.nota,
                            Productor: r.productor,
                            //
                            ProductorFamilia: f.productorFamilia,
                            Familia: f.familia,
                            HastaFecha: f.fecha
                        })
                    })
                } else {
                    resultadoExcel.push({
                        Nombre: r.titulo,
                        Latitud: r.posicion.lat,
                        Longitud: r.posicion.lng,
                        Tipo: r.tipo !== -1 ? state.tiposDePuntos[r.tipo] : '',
                        //Tipo: state.tiposDePuntos[r.tipo],
                        Nota: r.nota,
                        Productor: r.productor
                    })
                }
            })

            estado.todosPuntos = resultado
            estado.lineasExcel = resultadoExcel

            // Calcular el punto central de puntos a ver
            let posC = calculaCentroDePuntos(resultado)

            // Fuerza Renderizado
            setEstado({
                ...estado,
                todosPuntos: resultado,
                puntosAVer: resultado,
                lineasExcel: resultadoExcel,
                posCentral: posC,
            })

            actions.cambiarMensaje("")

        } else {
            actions.cambiarMensaje(t("MisInformes.NoHayLineasVenta"))
        }
    }


    function calculaCentroDePuntos(puntos) {
        let centro = { lat: 0, lng: 0 }
        let maxLat = 0
        let minLat = 90
        let maxLon = 0
        let minLon = -90

        if (puntos !== undefined) {
            for (let i = 0; i < puntos.length; i++) {
                let p = puntos[i].posicion
                // Latitud
                if (maxLat < p.lat) {
                    maxLat = p.lat
                }
                if (minLat > p.lat) {
                    minLat = p.lat
                }
                // Longitud negativas
                if (maxLon > p.lng) {
                    maxLon = p.lng
                }
                if (minLon < p.lng) {
                    minLon = p.lng
                }
            }
        }
        centro.lat = (maxLat + minLat) / 2
        centro.lng = (maxLon + minLon) / 2

        return centro
    }



    const nuevoPunto = (event) => {
        //console.log(estado.soloPuntosCad === false && estado.soloPuntosMios === false)
        if (estado.soloPuntosCad === false && estado.soloPuntosMios === false) {
            //Genera un punto
            let tp = {
                numero: undefined,
                productor: state.codProveedor,
                titulo: "",
                tipo: 0,
                poblacion: "",
                posicion: event.detail.latLng,
                nota: "",
                familiasMias: [],
                familiasAjenas: [],
                visible: 0,
                open: false
            }
            estado.dialogCrearModificar = tp
            setEstado({ ...estado, dialogCrearModificar: tp })
        }
    }


    const modificaPunto = (p) => (event) => {
        estado.dialogCrearModificar = p
        setEstado({ ...estado, dialogCrearModificar: p })
    }


    const puntoExistenteOpen = (i) => (event) => {
        let pAV = [...estado.puntosAVer]
        pAV[i].open = true
        setEstado({ ...estado, puntosAVer: pAV })
    }

    const puntoExistenteClose = (i) => (event) => {
        let pAV = [...estado.puntosAVer]
        pAV[i].open = false
        setEstado({ ...estado, puntosAVer: pAV })
    }


    const handleMiosTodos = (event) => {
        let tP = !estado.soloPuntosMios
        //
        let filtrados = !estado.soloPuntosMios ?
            estado.todosPuntos.filter(p => { return p.productor === state.codProveedor })
            :
            estado.todosPuntos
        setEstado({ ...estado, soloPuntosMios: tP, puntosAVer: filtrados })
    }

    const handleCadTodos = (event) => {
        let tP = estado.soloPuntosCad
        tP = !tP
        //
        let filtrados = []
        let fecha = new Date()
        let fechaDentroMes = (fecha.setMonth(fecha.getMonth() + 1))
        let tpFecha
        let tpPunto = ''
        let ubiAVer = true
        if (tP) {
            estado.puntosAVer.forEach((p, k) => {
                p.familiasMias.forEach((f, h) => {
                    tpFecha = (new Date(f.fecha.substr(6, 4), f.fecha.substr(3, 2) - 1, f.fecha.substr(0, 2))).getTime()
                    if (tpFecha < fechaDentroMes) {
                        if (tpPunto !== p.PunNum) {
                            tpPunto = p.PunNum
                            filtrados.push(p)
                        }
                    }
                })
            })
            ubiAVer = true
        } else {
            filtrados = estado.todosPuntos
            ubiAVer = false
        }
        setEstado({ ...estado, soloPuntosCad: tP, soloPuntosMios: ubiAVer, puntosAVer: filtrados })
        /*
        let filtrados = !estado.soloPuntosMios ? estado.todosPuntos.filter(p => {
            return p.productor === state.codProveedor
          })
          :
          estado.todosPuntos
        */
    }




    async function borraPunto(n) {
        // Solo permitir borrar si todas las familia son mias
        await borrarPunto({ numero: estado.todosPuntos[n].numero })
            .then((data) => {
                if (data.error) {
                    console.log(data.error, data)
                }
                else {
                    // Copia de los Arrays. //
                    let pAV = [...estado.puntosAVer]
                    let tPu = [...estado.todosPuntos]
                    // numero Borrado. //
                    let numero = pAV[n].numero
                    pAV[n].open = false
                    pAV.splice(n, 1);
                    // Localizar el numero en el Array de Todos los Puntos. //
                    let indice = BuscaIndice(tPu, "numero", numero)
                    if (indice !== -1) {
                        tPu.splice(indice, 1)
                    }
                    // Actualizar Estado. //
                    setEstado({ ...estado, puntosAVer: pAV, todosPuntos: tPu })
                }
            })
    }




    // Se usará si hay que cambiar la fecha en cada linea
    async function actualizadorPunto(modo, p) {
        // Copias de los Arrays. //
        let pAVer = [...estado.puntosAVer]
        let toPun = [...estado.todosPuntos]
        let alta = false
        if (modo !== 0) {
            // Guardar el Punto
            await updatePuntoProvCoor({ proveedor: state.codProveedor, esProductor: state.esProductor ? "1" : "0", punto: p })
                .then((data) => {
                    if (data.error) {
                        console.log(data.error)
                    }
                    else {
                        if (modo === 1) {
                            // Guardar ID del Punto. //
                            if (p.numero === undefined) {
                                alta = true
                                p.numero = data.data.insertId
                            }
                            // Guardar Normal
                            let tpFamMias = p.nuevasFamilias.filter((f) => {
                                return f.Activa
                            })
                            // Traslada las que nos sirven
                            p.familiasMias = []
                            tpFamMias.forEach(f => {
                                // Convertir Fecha aaaa-mm-dd a dd-mm-aaaa
                                let fecha = f.Fecha.substr(8, 2) + "-" + f.Fecha.substr(5, 2) + "-" + f.Fecha.substr(0, 4)
                                p.familiasMias.push({
                                    codFamilia: f.Codigo.slice(0, 3), // f.Codigo,
                                    codSubFamilia: f.Codigo.slice(3),
                                    familia: f.Nombre,
                                    fecha: fecha, // dd-mm-aaaa
                                    productorFamilia: state.codProveedor
                                })
                            })
                        } else if (modo === 2) {
                            // Solo Publicar
                        } else if (modo === 3) {
                            // Solo Despublicar
                        }
                        //estado.dialogCrearModificar = ""
                        //
                        // Busca el punto, si existe lo actualiza, si no lo añade
                        // Si es alta
                        if (alta) {
                            // añadir punto nuevo a los arrays. //
                            toPun.push(p)
                            pAVer.push(p)
                        } else {
                            // Modificar el Punto. //
                            let indice = BuscaIndice(toPun, "numero", p.numero)
                            // Si lo encuentra //
                            if (indice !== -1) {
                                // Sustituir el punto por el dato modificado. //
                                //    toPun.splice(indice, 1, p)
                                toPun[indice] = p
                                pAVer[indice] = p
                            }
                        }
                        //setEstado({ ...estado, puntosAVer: pAVer, todosPuntos: tp, dialogCrearModificar: "" })
                    }
                })
        }
        // Tanto si se guarda como si no
        //estado.dialogCrearModificar = ""
        setEstado({ ...estado, dialogCrearModificar: "", puntosAVer: pAVer, todosPuntos: toPun })
    }


    // Borrar la Ubicacion
    const handleClickBorrarUbicacion = (n) => (event) => {
        borraPunto(n)
    }


    const soltamosPunto = (n) => (event) => {
        let tP = estado.todosPuntos
        tP[n].posicion = event.latLng
        setEstado({ ...estado, todosPuntos: tP })
        // Hay que salvar en la base de datos
    }



    function colorRotulo(p, t) {
        let colorFinal = ''
        if (EsUsuarioEspecial(state.emailUsuario) !== -1) {
            // Es Eneek o Geroa
            colorFinal = 'rojo'
        } else if (p.productor === state.codProveedor) {
            colorFinal = 'verde'
        } else {
            colorFinal = 'naranja'
        }
        colorFinal += t
        //
        return colorFinal
    }


    function desabilitadoBorrar(p) {
        let desabilitado = false
        if (p.productor !== state.codProveedor) {
            desabilitado = true
        } else if (p.familiasAjenas.length > 0) {
            desabilitado = true
            if (p.familiasAjenas[0].codFamilia === null) {
                desabilitado = false
            }
        }
        return desabilitado
    }



    const tituloYFiltros = (
        <Box sx={{ display: 'flex' }} height="50px">
            <Box sx={{ width: 5 / 6 }}>
                <Typography variant="verde30">
                    {t("Mapas.Mapa")}
                </Typography>
            </Box>
            <Button
                variant="contained"
                //startIcon={<SendIcon />}
                sx={{ mt: 1.1, mr: 2, width: 330, height: 30 }}
                onClick={handleCadTodos}
            >
                {!estado.soloPuntosCad ? t("Mapas.ACaducar") : t("Mapas.TodasUbicaciones")}
            </Button>
            <Button
                variant="contained"
                //startIcon={<SendIcon />}
                sx={{ mt: 1.1, width: 330, height: 30 }}
                onClick={handleMiosTodos}
            >
                {!estado.soloPuntosMios ? t("Mapas.MisUbicaciones") : t("Mapas.TodasUbicaciones")}
            </Button>
            <Box sx={{ mt: 2, ml: 2 }}>
                <BotonXlsx data={estado.lineasExcel} filename="puntosMapa" />
            </Box>
        </Box>
    )


    const ampliacionPunto = (p, n) => (
        <Fragment key={"P_" + p.numero}>
            <Grid container>
                <Grid item xs={8}>
                    <Typography variant={colorRotulo(p, '18')}>
                        {p.titulo}
                    </Typography>
                    <br />
                    <Typography variant={colorRotulo(p, '15')}>
                        {p.poblacion}
                    </Typography>
                    <br />
                    <Typography variant="gris15">
                        {state.tiposDePuntos[p.tipo]}
                    </Typography>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        startIcon={<EditIcon />}
                        sx={{ mt: 1, mr: 1, width: 100, height: 26 }}
                        onClick={modificaPunto(p)}
                    >
                        {t("MisMapas.Editar")}
                    </Button>
                    <br />
                    <Button
                        variant="contained"
                        disabled={desabilitadoBorrar(p)}
                        startIcon={<DeleteForeverIcon />}
                        sx={{ mt: 0.5, mr: 1, mb: 1, width: 100, height: 26, bgcolor: ThemeGeroa.palette.purpura.main, color: ThemeGeroa.palette.blanco.main }}
                        onClick={handleClickBorrarUbicacion(n)}
                    >
                        {t("MisMapas.Borrar")}
                    </Button>
                </Grid>
            </Grid>
            <Typography variant="gris13">
                {p.nota}
            </Typography>
            <Divider sx={{ mb: 1 }} />
            {(p.familiasMias.length !== 0 || p.familiasAjenas.length !== 0) &&
                <Typography variant="azul16">
                    {t("MisMapas.TiposProducto") + ":"}
                </Typography>
            }
            <br />
            {p.familiasMias.length !== 0 &&
                p.familiasMias.map((f, i) => (
                    <Grid key={"fp" + i} container sx={{ mb: 1.5, mt: i === 0 ? 1 : -1 }}>
                        <Box sx={{ width: 8 / 12 }}>
                            <Typography noWrap={true} sx={{ ml: 2, mr: 1 }} variant={f.productorFamilia === state.codProveedor ? "verde13" : "naranja11"}>
                                {/*f.codFamilia !== null ? f.familia + " (" + f.fecha.substr(8, 2) + f.fecha.substr(4, 4) + f.fecha.substr(0, 4) + ")" : ""*/}
                                {f.codFamilia !== null ? f.familia + " (" + f.fecha + ")" : ""}
                            </Typography>
                        </Box>
                        <br />
                    </Grid>
                ))
            }
            {p.familiasAjenas.length !== 0 &&
                p.familiasAjenas.map((f, i) => (
                    (BuscaIndice(p.familiasMias, 'codFamilia', f.codFamilia) === -1 &&
                        <Grid key={"fa" + i} container sx={{ mb: 1.5, mt: i === 0 ? 1 : -1 }}>
                            <Box sx={{ width: 8 / 12 }}>
                                <Typography noWrap={true} sx={{ ml: 2, mr: 1 }} variant={f.productorFamilia === state.codProveedor ? "verde13" : "naranja11"}>
                                    {f.codFamilia !== null ? f.familia : ""}
                                </Typography>
                            </Box>
                            <br />
                        </Grid>
                    )
                ))
            }
        </Fragment >
    )




    function colorDelPunto(p) {
        let color
        // Esta a medias de definir ROJO
        if (p.visible === 0) {
            color = "red"
        } else if (p.productor === state.codProveedor) {
            color = ThemeGeroa.palette.primary.main
            //  color = "blue"
        } else {
            color = "#ff6333"
        }
        return color
    }

    function colorDelGlyph(p) {
        let color
        // Esta a medias de definir ROJO
        if (p.visible === 0) {
            color = "white"
        } else if (p.productor === state.codProveedor) {
            color = "white"
        } else {
            color = "white"
        }
        return color
    }

    function colorDelBorde(p) {
        let color
        // Esta a medias de definir ROJO
        if (p.visible === 0) {
            color = "red"
        } else if (p.productor === state.codProveedor) {
            color = ThemeGeroa.palette.primary.main
        } else {
            color = "orange"
        }
        return color
    }



    const listaMapa = (
        <Grid sx={{ border: "1px solid green", height: "92%", width: "100%" }}>
            <Fragment>
                <APIProvider apiKey={config.passMapas}>
                    <Map
                        onClick={nuevoPunto}
                        zoom={9}
                        //center={posEneek}
                        center={estado.posCentral}
                        mapId={'MiMapa'}
                        loading={"async"}
                    >
                        {estado.puntosAVer.map((p, i) => (
                            <Fragment key={"k1_" + i}>
                                {(p.productor === state.codProveedor) && (p.visible === 0)
                                    ?
                                    <AdvancedMarker
                                        key={"k2_" + i}
                                        position={p.posicion}
                                        draggable={true}
                                        onClick={puntoExistenteOpen(i)}
                                        onDragEnd={soltamosPunto(i)}
                                    >
                                        {/*<PuntoMapa punto={p} />*/}

                                        <Pin
                                            background={colorDelPunto(p)}
                                            borderColor={colorDelBorde(p)}
                                            glyphColor={colorDelGlyph(p)}
                                        >
                                        </Pin>
                                    </AdvancedMarker>
                                    :
                                    <AdvancedMarker
                                        key={"k2_" + i}
                                        position={p.posicion}
                                        draggable={false}
                                        onClick={puntoExistenteOpen(i)}
                                    //onDragEnd={soltamosPunto(i)}
                                    >
                                        {/*<PuntoMapa punto={p} />*/}

                                        <Pin
                                            background={colorDelPunto(p)}
                                            borderColor={colorDelBorde(p)}
                                            glyphColor={colorDelGlyph(p)}
                                        >
                                        </Pin>
                                    </AdvancedMarker>
                                }
                                {p.open && <InfoWindow
                                    key={"k3_" + i}
                                    position={p.posicion}
                                    minWidth={400}
                                    onCloseClick={puntoExistenteClose(i)}
                                >
                                    {ampliacionPunto(p, i)}
                                </InfoWindow>}
                            </Fragment>
                        ))}
                    </Map>
                </APIProvider>
            </Fragment>
        </Grid>
    )

    return (
        <ThemeProvider theme={ThemeGeroa}>
            <Container sx={{ mt: 1.5, height: "90vh", width: "80vw" }}>
                {tituloYFiltros}

                {estado.dialogCrearModificar !== '' &&
                    <DialogCrearModificarPunto
                        punto={estado.dialogCrearModificar}
                        numeroPunto={1}
                        actualizador={actualizadorPunto}
                    ></DialogCrearModificarPunto>
                }
                {listaMapa}
                <br />
            </Container>
        </ThemeProvider>
    );
}

export default withTranslation()(MisMapas2)

